button.react-aria-Button {
    --button-border-radius: var(--ht-radius-default);
    --button-cursor: pointer;
    --button-padding: var(--ht-spacing-150) var(--ht-spacing-300);
    --button-background: none;
    --button-color: var(--ht-color-text-brand);
    --button-state-layer-box-shadow: inset 0 0 0 1px var(--ht-color-border-default);
    --button-state-layer-background-hover: var(--ht-color-overlay-primary-hover);
    --button-state-layer-box-shadow-hover: var(--button-state-layer-box-shadow);
    --button-state-layer-background-focus-visible: var(--ht-color-overlay-primary-focus);
    --button-state-layer-box-shadow-focus-visible: 0 0 0 2px var(--ht-color-border-focus);
    --button-state-layer-background-pressed: var(--ht-color-overlay-primary-pressed);
    --button-state-layer-box-shadow-pressed: var(--button-state-layer-box-shadow);
    --button-state-layer-box-shadow-disabled: inset 0 0 0 1px var(--ht-color-border-disabled);
    --button-background-disabled: none;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--button-padding);
    gap: var(--ht-spacing-button-gap-default);
    border-radius: var(--button-border-radius);
    cursor: var(--button-cursor);
    border: 0;
    outline: 0;
    background-color: var(--button-background) !important;
    font-weight: var(--ht-typography-label-md-font-weight);
    line-height: var(--ht-typography-label-md-line-height);
    font-size: var(--ht-typography-label-md-font-size);
    font-family: var(--ht-typography-label-md-font-family);
    color: var(--button-color);
    transition: 150ms;
    transition-property: background, box-shadow;
}

button.react-aria-Button--full-width {
    width: 100%;
}

button.react-aria-Button::after {
    position: absolute;
    display: block;
    content: "";
    border-radius: var(--button-border-radius);
    height: 100%;
    width: 100%;
    transition: 150ms;
    transition-property: background, box-shadow;
    box-shadow: var(--button-state-layer-box-shadow);
}

button.react-aria-Button[data-hovered]::after {
    background: var(--button-state-layer-background-hover);
    box-shadow: var(--button-state-layer-box-shadow-hover);
}

button.react-aria-Button[data-focus-visible]::after {
    background: var(--button-state-layer-background-focus-visible);
    box-shadow: var(--button-state-layer-box-shadow-focus-visible);
}

button.react-aria-Button[data-pressed]::after {
    background: var(--button-state-layer-background-pressed);
    box-shadow: var(--button-state-layer-box-shadow-pressed);
}

button.react-aria-Button[data-disabled] {
    --button-cursor: not-allowed;
    --button-color: var(--ht-color-text-disabled);
    --button-background: var(--button-background-disabled);
}

button.react-aria-Button[data-disabled]::after {
    box-shadow: var(--button-state-layer-box-shadow-disabled);
}
